<template>

  <component v-bind:is="componentType" :to="{name:route}" class="financial_card" :class="!route?'add_padding':''">
    <div class="financial_card-info">
      <div>
        <div class="financial_card-info--title">{{ title }}</div>
        <div class="financial_card-info--count">{{ formatNumber(number, is_price) }}</div>
      </div>
      <component v-if="icon" v-bind:is="icon"/>

    </div>
    <div class="financial_card-link rotate-icon" v-if="route">
      <span>{{ $t('common.show_details') }}</span>
      <btn-arrow-left-icon/>
    </div>
  </component>
</template>

<script>
export default {
  name: 'FinancialCard',
  props: {
    title: String,
    route: String,
    number: [String, Number],
    is_price: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: null,
    },

  },

  computed: {
    componentType() {
      if (this.route)
        return 'router-link'
      return 'div'
    }
  }
};
</script>
